import { useEffect, useState } from 'react';
import './App.css';
import Logo from './images/laacademia.png'
import Arriba from './images/right-arrow.png'
import Abajo from './images/downstairs.png'
import Profesor from './images/clase.png'

const dayNames = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const calendars = [
  "537jo4ohjkas1g345d66bh96nc@group.calendar.google.com",
  "nkhuid1sljvitcl01hv9hnmti8@group.calendar.google.com",
  "nm23362atv2fkj2fqls8ngc9us@group.calendar.google.com",
  "55pcag6s599tjcp5neu9e9m7vk@group.calendar.google.com",
  "qll1l4heqobq7vhvhcenjsare0@group.calendar.google.com",
  "r3ub7p56nji1q9vnp8ts1unjf8@group.calendar.google.com",
  "hepu9f2pdvvjg06n85oddcq35g@group.calendar.google.com",
  "dmg6vj60b2fstqtdj48p5e8dos@group.calendar.google.com",
  "7ii1gv295qupa00nh2bg6oddfs@group.calendar.google.com"
];

function formatTimeNumber(number) {
  return number < 10 ? '0' + number : number;
}

function App() {
  const [time, setTime] = useState('');
  const [dateTime, setDateTime] = useState([]);

  const [ahoraClases, setAhoraClases] = useState(null);
  const [proxClases, setProxClases] = useState(null);

  function updateClock() {
    const now = new Date();

    setTime(formatTimeNumber(now.getHours()) + ':' + formatTimeNumber(now.getMinutes()));
    setDateTime([dayNames[now.getDay()] + ',', formatTimeNumber(now.getDate()) + ' de ' + monthNames[now.getMonth()]]);

    //Update events every 15 minutes
    if ([0, 15, 30, 45].includes(now.getMinutes()) && now.getSeconds() === 0) loadEvents();
  }

  function loadEvents() {
    const endOfDay = new Date();

    const before = new Date(endOfDay.getTime() - 1800000);

    endOfDay.setHours(23, 59, 59, 999)

    let promises = []
    console.log(before.toISOString())
    console.log(endOfDay.toISOString())
    for (const calendarId of calendars) {
      const calendarURL = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?timeMin=${before.toISOString()}&timeMax=${endOfDay.toISOString()}&key=${process.env.REACT_APP_CALENDAR_API_KEY}&orderBy=startTime&singleEvents=true`
      promises.push(fetch(calendarURL).then(res => res.json()));
    }

    let ahoraClases = []
    let proxClases = []

    const allData = Promise.all(promises)

    allData.then(calendars => {
      for (const calendar of calendars) {
        if (calendar.error) {
          //Fail to fetch
          console.log(calendar.error)
        } else {
          for (const event of calendar["items"]) {
            let e = {
              startTime: new Date(event.start.dateTime),
              endTime: new Date(event.end.dateTime),
              title: event.summary,
              subtitle: event.location,
              aula: calendar.summary
            }

            if (e.startTime.getTime() < new Date().getTime()) {
              ahoraClases.push(e)
            } else {
              proxClases.push(e)
            }
          }

          ahoraClases.sort(function (a, b) {
            return a.startTime.getTime() - b.startTime.getTime();
          });
          proxClases.sort(function (a, b) {
            return a.startTime.getTime() - b.startTime.getTime();
          });

          if (ahoraClases.length < 1) {
            proxClases = proxClases.slice(0, 10)
          } else if (ahoraClases.length < 5) {
            proxClases = proxClases.slice(0, 5)
          } else if (ahoraClases.length >= 5) {
            proxClases = []
          }

          setAhoraClases(ahoraClases)
          setProxClases(proxClases)
        }
      }
    })
  }

  useEffect(() => {
    //Load the events once for the first time
    if (ahoraClases === null && proxClases === null) loadEvents();

    const clockInterval = setInterval(updateClock, 1000);

    return () => clearInterval(clockInterval);
  })

  return (
    <>
      <header>
        <img className='logo' src={Logo} alt='Logo Laacademia' />
        <div className='date-time'>
          <div id='date'>{dateTime.map(text => <div key={text}>{text}</div>)}</div>
          <div id='time'>{time}</div>
        </div>
      </header>

      <main className='content'>
        {/* No hay más clases */}
        {ahoraClases?.length === 0 && proxClases?.length === 0 &&
          <div className='no-classes'>
            <img className='no-classes-icon' src={Profesor} alt='' />
            No hay más clases hoy
          </div>
        }

        {/* Clases en curso */}
        {ahoraClases !== null && ahoraClases?.length !== 0 &&
          <>
            <div className='section-title'>Ahora</div>
            <div className='section-now' style={{ marginBottom: ahoraClases.length < 3 ? '60px' : '30px', gap: ahoraClases.length < 3 ? '8vh 4vw' : (ahoraClases.length < 5 ? '4vh 4vw' : (ahoraClases.length < 7 ? '8vh 4vw' : '2vh 4vw')) }}>
              {ahoraClases?.map((event, index) => <Event key={index} event={event} lg></Event>)}
            </div>
          </>
        }
        {/* Próximas clases */}
        {proxClases !== null && proxClases?.length !== 0 &&
          <>
            <div className='section-title'>Próximas clases</div>
            <div className='section-next' style={{ gap: ahoraClases.length < 1 ? '10vh 5vw' : '4vh 4vw' }}>
              {proxClases?.map((event, index) => <Event key={index} event={event}></Event>)}
            </div>
          </>
        }
      </main>
    </>
  );
}

function Event(params) {
  const aulasAbajo = ['Aula 1', 'Aula 2', 'Aula 3', 'Aula 4', 'Aula 7']
  const aulasArriba = ['Aula 5', 'Aula 6', 'Aula 5 + 6', 'Aula 8 - OUI']

  return (
    <div className={params.lg ? 'event-large' : 'event-small'}>
      <div className='event-time'>
        <div className='event-hour'>{formatTimeNumber(params.event?.startTime.getHours())}</div>
        <div className='event-minutes'>{formatTimeNumber(params.event?.startTime.getMinutes())}</div>
      </div>
      <div className='event-text'>
        <div className='event-text-sub'>
          <div className='event-title'>{params.event?.title}</div>
          <div className='event-subtitle'>{params.event?.subtitle}</div>
        </div>
        <div className='event-aula'>{params.event?.aula}<img className='event-aula-icon' src={aulasAbajo.includes(params.event?.aula) ? Abajo : (aulasArriba.includes(params.event?.aula) ? Arriba : null)} alt='' /></div>
      </div>
    </div>
  );
}

export default App;